<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Material Request">
        <b-form @keyup.enter.prevent="" @submit.prevent="submitNewMaterialRequest">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <br />
              <b-form-group
                label="To *"
                label-for="material_request_to"
                label-cols-md="12"
              >
                <b-form-input
                  id="material_request_to"
                  v-model="materialRequestTo"
                  placeholder="To."
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="From *"
                label-for="material_request_cc"
                label-cols-md="12"
              >
                <b-form-input
                  id="material_request_cc"
                  v-model="materialRequestCc"
                  placeholder="From"
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="Reason"
                label-for="material_request_reason"
                label-cols-md="12"
              >
                <b-form-input
                  id="material_request_reason"
                  v-model="materialRequestReason"
                  placeholder="Reason"
                />
              </b-form-group>
              <b-form-group label="Items *" label-for="chosenItem">
                <Select2 v-model="chosenItem" :settings="settings" @select="putItem($event)" />
              </b-form-group>
              <b-row>
                <b-col cols="6" class="text-center font-weight-bold">
                  Item name
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Item Unit
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Item QTY
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Actions
                </b-col>
              </b-row>
              <p />
              <b-row v-for="chosen in chosens" :key="chosen.value">
                <br />
                <b-col cols="6 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-list-group-item href="#">
                    {{ capitalizeWords(chosen.unitname) }}
                  </b-list-group-item>
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                    v-model="chosen.qty"
                    type="number"
                    placeholder="Qty"
                    min="1"
                    required="required"
                  />
                </b-col>
                <b-col cols="2 text-center">
                  <b-button
                    size="sm"
                    type="button"
                    variant="danger"
                    class="mr-1"
                    @click="removeRow(chosen.value)"
                  >
                    <feather-icon icon="TrashIcon" size="18" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="12">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="submitButtonDisabled"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BFormFile,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import Select2 from 'v-select2-component'

import { ref } from "@vue/composition-api"

const VersionCheck = ""
const items = []
const chosenItem = ref(3)
const chosens = []
const materialRequestTo = "Warehouse Lokal"
const materialRequestCc = ""
const materialRequestReason = ""
const materialRequestQtyRequested = 0
const materialRequestStatus = ""
const submitButtonDisabled = false
export default {
  components: {
    BFormFile,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    Select2,
  },
  directives: {
    Ripple,
  },
  data() {
    const userToken = this.$cookies.get("userToken")
    return {
      VersionCheck,
      items,
      chosenItem,
      chosens,
      materialRequestTo,
      materialRequestCc,
      materialRequestReason,
      materialRequestQtyRequested,
      materialRequestStatus,
      readonly: false,
      submitButtonDisabled,
      settings: {
        ajax: {
          url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`,
          beforeSend: xhr => {
            xhr.setRequestHeader('Authorization', `Bearer ${userToken}`)
          },
          data: params => {
            const query = {
              search: params.term,
              offset: 0,
              limit: 50,
            }

            // Query parameters will be ?search=[term]&type=public
            return query
          },
          processResults: response => {
            let resItems = response.data
            resItems = resItems.map(item => {
              item.text = `${item.item_code} - ${item.item_name}`
              item.id = item.item_id
              item.unitname = item.unit ? item.unit.unit_name : '-'
              return item
            })
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: resItems,
            }
          },
        },
      },
    }
  },
  created() {
    window.setInterval(() => {
      this.removeReadOnly()
    }, 1000)
  },
  async mounted() {
    // await this.getItems()
    this.chosens = []
  },
  beforeMount() {},
  methods: {
    removeReadOnly() {
      const searches = document.getElementsByClassName("vs__search")
      for (let i = 0; i < searches.length; i += 1) {
        searches[i].removeAttribute("readonly")
      }
    },
    getItems(search = "") {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}?offset=0&limit=50search=${search}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = response.data.data.map(elem => ({
              text: `${elem.item_code} - ${elem.item_name}`,
              id: elem.item_id,
            }))
            this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    submitNewMaterialRequest() {
      this.submitButtonDisabled = true
      this.chosens.map(elem => {
        this.materialRequestQtyRequested += parseInt(elem.qty)
      })
      const body = {
        material_request_to: this.materialRequestTo,
        material_request_cc: this.materialRequestCc,
        material_request_reason: this.materialRequestReason,
        material_request_qty_requested: this.materialRequestQtyRequested,
        material_request_items: JSON.stringify(this.chosens),
        time: moment(),
      }
      if (this.chosens.length > 0) {
        const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST}`,
            body,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              this.chosens = []
              this.materialRequestQtyRequested = 0
              this.materialRequestReason = ""
              this.materialRequestCc = ""
              this.materialRequestTo = ""
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Material Request Success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              this.$router.replace({ name: "apps-material-request-list" })
            } else {
              this.submitButtonDisabled = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Material Request Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Material Request Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You have to choose at least one item",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem({ id, text, unitname }) {
      if (this.chosens.length > 0) {
        const item = {
          label: text,
          value: id,
          qty: 1,
          id: this.chosens.length + 1,
          unitname,
          shelf: "",
        }
        this.chosens.push(item)
        // }
      } else {
        const item = {
          label: text,
          value: id,
          qty: 1,
          id: this.chosens.length,
          unitname,
          shelf: "",
        }

        this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.value !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    previous() {
      this.$router.push({ name: "apps-material-request-list" })
    },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
  },
}
</script>
